/*------- about us area start -------*/
.about-content {
    @media #{$md-device} {
        margin-top: 40px;
    }
    @media #{$sm-device} {
        margin-top: 40px;
        margin-bottom: -3px;
    }
    @media #{$xs-device} {
        margin-bottom: -8px;
    }
}
.about {
    &-title {
        font-size: 42px;
        font-weight: 900;
        line-height: 1.1;
        margin-top: -6px;
        margin-bottom: 18px;
        // responsive
        @media #{$lg-device} {
            font-size: 28px;
        }
        @media #{$sm-device} {
            font-size: 24px;
            margin-bottom: 10px;
        }
    }
    &-subtitle {
        font-weight: 400;
        padding-bottom: 5px;
    }
    &-info {
        padding-top: 20px;
        margin-top: 20px;
        border-top: 1px solid $bdr-color;
        // responsive
        @media #{$md-device} {
            margin-bottom: -5px;
        }
        li {
            font-size: 16px;
            font-weight: 700;
            line-height: 1.4;
            color: $paragraph-light;
            margin-bottom: 10px;
            display: flex;
            &:last-child {
                margin-bottom: 0;
            }
            i {
                font-size: 18px;
                line-height: 1.4;
                padding-right: 15px;
            }
            &.add {
                max-width: 56%;
                // responsive
                @media #{$lg-device} {
                    max-width: 70%;
                }
            }
        }
    }
}
/*------- about us area end -------*/

/*-------- choosing area start --------*/
.choosing-area {
    margin-bottom: -8px;
}
.single-choose-item  {
    @media #{$sm-device} {
        padding: 0 20px;
    }
    i {
        color: #969ca7;
        font-size: 44px;
        transition: 0.4s;
    }
    h4 {
        padding: 15px 0 10px;
    }
    &:hover {
        i {
            color: $theme-color;
        }
    }
}
/*-------- choosing area end --------*/

/*------- team area start -------*/
.team-member {
    transition: 0.4s;
    .team-thumb {
        position: relative;
        text-align: center;
        overflow: hidden;
        img {
            width: 100%;
        }
        .team-social {
            bottom: 0;
            left: 0;
            width: 100%;
            padding: 10px 0;
            position: absolute;
            background-color: $white;
            transform: translateY(100%);
            transition: 0.4s;
            opacity: 0;
            visibility: hidden;
            border: 1px solid $bdr-color;
            a {
                color: $black-soft;
                width: 30px;
                height: 30px;
                font-size: 14px;
                display: inline-block;
                text-align: center;
                line-height: 30px;
                background-color: #f5f5f5;
                border-radius: 5px;
                margin-right: 5px;
                &:hover {
                    color: $white;
                    background-color: $theme-color;
                    border-color: $theme-color;
                }
            }
        }
    }
    .team-content {
        .team-member-name {
            padding-top: 25px;
        }
        p {
            line-height: 1;
            padding-top: 8px;
            color: $theme-color;
        }
    }
    &:hover {
        .team-social {
            opacity: 1;
            visibility: visible;
            transform: translateY(0); 
        }
    }
}
/*------- team area end -------*/