
/*------- blog item start -------*/
.blog-item-wrapper {
    .blog-post-item {
        padding: 20px;
        background-color: $white;
        box-shadow: 0 0 10px 0 rgba(195,195,195,.6);
        // responsive
        @media #{$xxs-device} {
            padding: 15px;
        }
        .read-more {
            background-color: $theme-color;
            color: $white;
            &:hover {
                background-color: $black-soft;
            }
        }
    }
}
.blog {
    &-post-item {
        display: flex;
        background-color: #f3f3f3;
        padding: 30px;
        transition: 0.4s;
        // responsive
        @media #{$md-device, $sm-device} {
            display: block;
        }
        @media #{$xxs-device} {
            padding: 15px;
        }
        &:hover {
            background-color: $white;
            box-shadow: 0 5px 20px 0 rgba(195,195,195,.6);
            .read-more {
                color: $white;
                background-color: $theme-color;
            }
        }
    }
    &-thumb {
        width: 170px;
        // responsive
        @media #{$md-device, $sm-device} {
            width: 100%;
        }
        img {
            width: 100%;
        }
        &:hover {
            img {
                opacity: 0.7;
            }
        }
    }
    &-content {
        padding-left: 20px;
        width: calc(100% -  170px);
        // responsive
        @media #{$md-device, $sm-device} {
            width: 100%;
            margin-top: 30px;
            padding-left: 0;
        }
    }
    &-title {
        font-weight: 900;
        line-height: 1.3;
        text-transform: uppercase;
        a {
            color:$black-soft;
            display: block;
            &:hover {
                color: $theme-color;
            }
        }
    }
    &-meta {
        padding-top: 10px;
        padding-bottom: 10px;
        span {
            font-size: 14px;
            color: $theme-color;
            margin-right: 15px;
            i {
                padding-right: 8px;
            }
            &:last-child {
                margin-right: 0;
            }
        }
    }
    &-desc {
        font-size: 16px;
        line-height: 1.5;
        padding-bottom: 10px;
    }
    &-list-item {
        .blog-thumb {
            width: 40%;
            // responsive
            @media #{$md-device, $sm-device} {
                width: 100%;
            }

        }
        .blog-content {
            width: calc(100% - 40%);
            // responsive
            @media #{$md-device, $sm-device} {
                width: 100%;
            }
        }
    }
}

.blog-carousel-active {
    &.slick-slider {
        .slick-slide > {
            div {
                margin-bottom: 10px;
    
                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
    }
}
/*------- blog item end -------*/

/*------ blog list item start ------*/
.blog-list-inner {
    .blog-post-item {
        display: flex;
        // responsive
        @media #{$sm-device} {
            display: block;
        }
        .blog-thumb {
            width: 40%;
            // responsive
            @media #{$sm-device} {
                width: 100%;
            }
        }
        .blog-content {
            padding-top: 0;
            padding-left: 20px;
            width: calc(100% - 40%);
            // responsive
            @media #{$sm-device} {
                width: 100%;
                padding-left: 0;
                padding-top: 30px;
            }
            .blog-title {
                margin-top: -5px;
            }
            .blog-meta {
                padding-top: 15px;
            }
            .blog-read-more {
                color: $black-soft;
                display: inline-block;
                margin-top: 10px;
                &:hover {
                    color: $theme-color;
                    text-decoration: underline;
                }
            }
        }
    }
}
/*------ blog list item end ------*/

/*----- blog sidebar start -------*/
.blog-sidebar-wrapper {
    // responsive
    @media #{$md-device} {
        margin-top: 80px;
    }
    @media #{$sm-device} {
        margin-top: 64px;
    }
    .blog-sidebar {
        &:last-child {
            margin-bottom: 0;
        }
    }
}
.blog-sidebar {
    margin-bottom: 30px;
    // background-color: #f3f3f3;
    box-shadow: 0 0 10px 0 rgba(195,195,195,.6);
    padding: 30px 15px;
    // sidebar title
    .title {
        line-height: 1;
        margin-top: -2px;
        margin-bottom: 25px;
        text-transform: capitalize;
    }

    // sidebar search form
    .search-field {
        width: calc(100% - 50px);
        border: 1px solid #ccc;
        padding: 0 10px;
        color: $paragraph;
        font-size: 14px;
        height: 50px;
        float: left;
        text-transform: capitalize;
    }
    .search-btn {
        width: 50px;
        height: 50px;
        margin-left: -4px;
        text-align: center;
        cursor: pointer;
        background-color: #f8f8f8;
        border: 1px solid #ccc;
        transition: 0.4s;
        i {
            font-size: 20px;
            line-height: 50px;
            color: $black-soft;
            transition: 0.4s;
        }
        &:hover {
            background-color: $theme-color;
            border-color: $theme-color;
            i {
                color: $white;
            }
        }
    }

    // archives
    .blog-archive {
        margin-top: -10px;
        li {
            line-height: 1;
            a {
                color: $paragraph;
                font-size: 14px;
                line-height: 1;
                text-transform: capitalize;
                padding: 13px 0;
                display: inline-block;
                &:hover {
                    color: $theme-color;
                }
            }
            &:last-child {
                a {
                    padding-bottom: 0;
                }
            }
            &:first-child {
                a {
                    padding-top: 0;
                }
            }
        }
    }

    // blog tags
    .blog-tags {
        margin-bottom: -5px;
        li {
            display: inline-block;
            margin-bottom: 5px; 
            a {
                color: $black-soft;
                font-size: 14px;
                border: 1px solid #ccc;
                padding: 6px 15px 4px;
                display: inline-block;
                text-transform: capitalize;
                &:hover {
                    color: $white;
                    background-color: $theme-color;
                    border-color: $theme-color;
                }
            }
        }
    }
}

// blog category
.blog-category {
    margin-top: -10px;
}

//recent post start
.recent-post {
    .recent-post-item  {
         &:last-child {
             margin-bottom: 0;
        }
    }
}
.recent-post-item {
    margin-bottom: 30px;
    display: flex;
    .product-thumb {
        max-width: 70px;
        flex: 1 0 70px;
    }
    .recent-post-description {
        padding-left: 10px;
        h6 {
            font-size: 14px;
            padding-bottom: 10px;
            text-transform: capitalize;
            // responsive
            @media #{$lg-device} {
                font-size: 13px;
                padding-bottom: 5px;
            }
            a {
                color:$black-soft;
                &:hover {
                    color: $theme-color;
                }
            }
        }
        p {
            line-height: 1;
            font-size: 14px;
            text-transform: capitalize;
        }
    }
}
/*----- blog sidebar end -------*/
