/*-------- offcanvas search start --------*/
.offcanvas-search-inner {
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    position: fixed;
    transition: 0.4s;
    background-color: rgba(0,0,0,0.7);
    opacity: 0;
    visibility: hidden;
    z-index: 9;
    &.show {
        opacity: 1;
        visibility: visible;
        .offcanvas-search-box  {
            transform: translateY(0);
        }
    }
    .offcanvas-close {
        width: 50px;
        height: 50px;
        text-align: center;
        background-color: $white;
        color: $theme-color;
        font-size: 22px;
        cursor: pointer;
        top: 50px;
        right: 50px;
        position: absolute;
        z-index: 9;
        transition: 0.4s;
        transition-delay: 0.6s;
        i {
            display: block;
            line-height: 50px;
            transition: 0.6s;
        }
        &:hover {
            i {
                transform: rotate(180deg);
            }
        }
    }
}

.offcanvas-search-box {
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    transform: translateY(50px);
    transition: 0.4s;
    transition-delay: 0.6s;
    .bdr-bottom {
        border-color: $theme-color;
    }
    input {
        width: 100%;
        border: none;
        color: $white;
        font-size: 16px;
        font-weight: 400;
        padding-right: 20px;
        padding-bottom: 15px;
        background-color: transparent;
        &::placeholder {
            color: $white;
            font-size: 16px;
            opacity: 1;
        }
    }
    .search-btn {
        display: flex;
        align-items: center;
        font-size: 16px;
        color: $white;
        line-height: 1;
        padding-bottom: 15px;
        text-transform: capitalize;
        i {
            display: block;
            line-height: 1;
            font-size: 28px;
            padding-right: 15px;
        }
        &:hover {
            color: $theme-color;
        }
    }
}
/*-------- offcanvas search end --------*/