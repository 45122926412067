/*------- Buttons Style here -------*/
.btn {
    font-size: 14px;
    color: $black-soft;
    line-height: 1;
    border-radius: 0;
    &-text {
        font-size: 16px;
        position: relative;
        text-transform: capitalize;
        &:before {
            bottom: -4px;
            left: 0;
            width: 100%;
            height: 2px;
            content: '';
            position: absolute;
            background-color: $black-soft;
        }
        &:hover {
            color: $theme-color;
            &:before {
                background-color: $theme-color;
            }
        }
    }
    &-cart {
        padding: 12px 25px;
        background-color: $white;
        border-radius: 30px;
        text-transform: capitalize;
        box-shadow: 0 2px 1px 0.5px rgba(0,0,0,0.05);
        &:hover {
            color: $white;
            background-color: $theme-color;
        }
    }
    &-cart2 {
        height: 40px;
        color: $white;
        font-size: 18px;
        font-weight: 700;
        line-height: 40px;
        border-radius: 50px;
        padding: 0 25px;
        background-color: $theme-color;
        &:hover {
            color: $white;
            background-color: $black-soft;
        }
    }
    &-sqr {
        color: $white;
        font-size: 16px;
        font-weight: 700;
        border-radius: 0;
        background-color: $theme-color;
        padding: 13px 25px;
        &:hover {
            color: $white;
            background-color: $black-soft;
        }
    }
    &.read-more {
        background-color: $white;
        padding: 10px 20px;
    }
    &-large {
        font-size: 20px;
        font-weight: 700;
        line-height: 1;
        color: $white;
        padding: 15px 30px;
        background-color: $black-soft;
        border-radius: 50px;
        // responsive
        @media #{$xs-device} {
            font-size: 18px;
            padding: 12px 25px;
        }
        &:hover {
            color: $white;
            background-color: $theme-color;
        }
        &.hover-color {
            &:hover {
                color: $white;
                background-color: $theme-color;
            }
        }
    }
    &-bg {
        background-color: $theme-color;
        padding: 13px 30px;
        &:hover {
            color: $white;
            background-color: $black-soft;
        }
    }
}