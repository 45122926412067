/*------ banner statistics style start ------*/
/* 1.0 banner statistics */

.banner {
    &-statistics {
        position: relative;
        &:before,
        &:after {
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            content: "";
            pointer-events: none;
            position: absolute;
            background-color: rgba($white, 0.4);
            transform: scale(0);
            transform-origin: top left;
        }
        &:after {
            bottom: 0;
            right: 0;
            transform-origin: bottom right;
        }
        &:hover {
            &:before,
            &:after {
                transform: scale(1);
                opacity: 0;
                transition-duration: 1.3s;
            }
        }
        img {
            width: 100%;
        }
    }
}

/*------ banner promotion area strat ------*/
.promotion {
    &-banner {
        // responsive
        @media #{$sm-device} {
            margin-top: 40px;
            text-align: center;
        }
    }
    &-title {
        font-size: 120px;
        color: $theme-color;
        font-weight: 400;
        line-height: 1;
        font-family: $special-font;
        // responsive
        @media #{$sm-device} {
            font-size: 90px;
        }
    }
    &-subtitle {
        font-size: 60px;
        font-weight: 500;
        line-height: 1;
        font-family: $special-font;
        // responsive
        @media #{$md-device, $sm-device} {
            font-size: 35px;
        }
        @media #{$xxs-device} {
            font-size: 24px;
        }
    }
    &-desc {
        color: #777;
        font-weight: 400;
        padding: 10px 0 30px;
        // responsive
        @media #{$xxs-device} {
            font-size: 18px;
        }
    }
}
/*------ banner promotion area end ------*/

/*------ banner statistics style end ------*/