/*------- common style css start -------*/
.container {
    max-width: 1200px;
}

.bdr-bottom {
    border-bottom: 1px solid $bdr-color;
}

.black-bg {
    background-color: #1a1a1a;
}
.theme-bg {
    background-color: $theme-color;
}
.black-soft {
    background-color: #282828;
}

.bg-gray {
    background-color: #f8f8f8;
}

/*-------- Custom Checkbox Style start --------*/
.custom-checkbox,
.custom-radio {

    &.custom-control {
        min-height: auto;
    }

    label,
    .custom-control-label {
        line-height: 1;
        padding-left: 10px;
        font-weight: 400;
        display: block;
        cursor: pointer;

        &:before {
            background-color: transparent;
            border: 1px solid #ddd;
            border-radius: 0;
            height: 15px;
            top: 0;
            width: 15px;
        }

        &:after {
            background-size: 10px 10px;
            height: 15px;
            top: 0;
        }

        a {
            color: $black-soft;
            font-weight: 500;

            &:hover {
                color: $theme-color;
            }
        }
    }

    input,
    .custom-control-input {
        outline: none;

        &:focus {

            ~.custom-control-label,
            label {
                &:before {
                    box-shadow: none;
                }
            }
        }

        &:checked {

            ~.custom-control-label,
            label {
                &:before {
                    background-color: $theme-color;
                    border-color: $theme-color;
                }
            }
        }
    }
}
/*-------- Custom Checkbox Style end --------*/

/*------- Custom Radio Button Style start -------*/
.custom-radio {
    label,
    .custom-control-label {
        &:before {
            border-radius: 50%;
            height: 14px;
            width: 14px;
        }

        &::after {
            background-size: 10px 10px;
            left: -22px;
            top: 2px;
            height: 10px;
            width: 10px;
        }
    }
}
/*------- Custom Radio Button Style end -------*/

/*----- product tab menu start -----*/
.product-tab-menu {
    margin-top: -6px;
    margin-bottom: 35px;
    ul {
        li {
            margin-right: 35px;
            // responsive
            @media #{$xs-device} {
                margin-right: 15px;
            }
            @media #{$xxs-device} {
                margin-bottom: 10px;
            }
            &:last-child {
                margin-right: 0;
            }
            a {
                font-size: 16px;
                font-weight: 900;
                line-height: 1.2;
                color: $black-soft; 
                text-transform: uppercase;
                position: relative;
                &:hover,
                &.active {
                    color: $theme-color;
                    &:before {
                        bottom: -5px;
                        opacity: 1;
                        visibility: visible;
                    }
                }
                &:before {
                    width: 100%;
                    height: 2px;
                    content: '';
                    position: absolute;
                    left: 0;
                    bottom: -10px;
                    background-color: $theme-color;
                    opacity: 0;
                    visibility: hidden;
                    transition: 0.4s;
                }
            }
        }
    }
}

// tab animation
.tab-content {
    .tab-pane {
        &.show {
            &.active {
                .product-item {
                    animation-name: zoomIn;
                    animation-duration: 1s;
                }
            }
        }
    } 
}

/*----- product tab menu start -----*/

/*----- brand logo style start -----*/
.brand-logo-wrapper {
    margin-top: 78px;
    // responsive
    @media #{$sm-device} {
        margin-top: 60px;
    }
}
.brand-item {
    background-color: $white;
    padding: 20px 0;
    img {
        margin: auto;
    }
}
.brand-logo-area {
    margin-top: -42px;
}
/*----- brand logo style end -----*/

/*----- hot deals item start -----*/
.hot-deals-item {
    border: 1px solid $theme-color;
    border-radius: 5px;
    .product-caption {
        padding: 20px;
        // responsive
        @media #{$sm-device} {
            padding: 20px 10px;
        }
    }
    .manufacturer-name {
        padding-bottom: 5px;
    }
    .product-name {
        font-size: 20px;
        padding-bottom: 30px;
    }
    .price-box {
        font-size: 21px;
        padding-top: 8px;
    }
}
// quantity progress bar
.progress {
    height: 15px;
    border-radius: 50px;
    &-quantity {
        position: relative;
        margin-top: 52px;
    }
    &-bar {
        background-color: $theme-color !important;
        width: 60%;
        &:nth-child(2) {
            width: 70%;
        }
    }
    &-title {
        display: flex;
        position: absolute;
        top: calc(-100% - 8px);
        left: 0;
        width: 100%;
        line-height: 1;
        justify-content: space-between;
    }
}
.product-sold,
.product-available {
    font-size: 14px;
    color: $paragraph-light;
    span {
        color: $theme-color;
        font-weight: 700;
    }
}

// product countdown
.product-countdown {
    display: flex;
    margin-top: 30px;
    .single-countdown {
        width: 60px;
        text-align: center;
        line-height: 1;
        margin-right: 10px;
        border-radius: 10%;
        padding: 15px 0;
        background-color: #eaeaea;

        &__time {
            font-size: 16px;
            font-weight: 600;
            display: inline-block;
            color: $black-soft;
        }

        &__text {
            font-size: 11px;
            display: block;
            color: $black-soft;
            line-height: 1;
            margin-top: 5px;
            text-transform: uppercase;
        }
        &:last-child {
            margin-right: 0;
        }
    }
}

.product-details-des {
    .product-countdown {
        margin: 20px 0 30px;
        display: flex;
        justify-content: flex-start;
    }
}
/*----- hot deals item start -----*/

/*----- deals tab area start -----*/
.deals-tab-wrapper {
    display: flex;
    // responsive
    @media #{$md-device, $sm-device} {
        display: block;
    }
}
.deals-tab-area {
    flex: 1 0 auto;
    margin-top: 20px;
    width: 135px;
    // responsive
    @media #{$md-device, $sm-device} {
        width: 100%;
    }
    .slick-list {
        .slick-slide {
            &.slick-current {
                .deals-nav-item {
                    &:before {
                        opacity: 1;
                        visibility: visible;
                    }
                }
            }
        }
    }
}
.deals-content-wrapper {
    padding-left: 0;
    width: calc(100% - 135px);
    // responsive
    @media #{$md-device, $sm-device} {
        width: 100%;
    }
}
.deals-nav-item {
    padding-bottom: 20px;
    padding-right: 15px;
    position: relative;
    &:before {
        top: 50%;
        right: 0;
        content: '';
        position: absolute;
        border-top: 15px solid transparent;
        border-bottom: 15px solid transparent;
        border-right: 15px solid $white;
        transform: translateY(-50%);
        opacity: 0;
        visibility: hidden;
        transition: 0.4s;
    }
}
.deals-content-item {
    border: 1px solid $bdr-color;
    background-color: $white;
    padding: 30px 50px 30px 20px;
    .deals-title {
        font-size: 40px;
        font-weight: 400;
        line-height: 1;
        padding-bottom: 25px;
        // responsive
        @media #{$lg-device, $sm-device} {
            font-size: 32px;
        }
        @media #{$xxs-device} {
            font-size: 26px;
        }
        a {
            color: $paragraph;
            &:hover {
                color: $theme-color;
            }
        }
    }
    .deals-desc {
        font-size: 18px;
        color: #777777;
        line-height: 1.4;
        padding-top: 20px;
    }
    .price-box {
        margin-top: 30px;
        margin-bottom: 30px;
        .price-regular {
            font-size: 42px;
            color: $theme-color-2;
            // responsive
            @media #{$sm-device} {
                font-size: 26px;
            }
        }
        .price-old {
            font-size: 18px;
        }
    }
}

.pos-special-products {
    background-image: url(../../assets/img/banner/bg_spe.jpg);
    width: 100%;
    display: inline-block;
    background-repeat: no-repeat;
    // responsive
    @media #{$md-device, $sm-device} {
        background: transparent;
    }
    .col-right {
        float: right;
        margin-left: auto;
    }
}

// deals style 2
.sp-product-content {
    padding: 30px;
    border: 1px solid $bdr-color;
    // responsive
    @media #{$xs-device} {
        padding: 30px 15px;
    }
    .sp-title {
        font-size: 28px;
        font-weight: 400;
        a {
            color: $paragraph;
            &:hover {
                color: $theme-color;
            }
        }
    }
    .ratings {
        padding: 15px 0;
    }
    .price {
        &-old {
            font-size: 18px;
        }
        &-regular {
            font-size: 40px;
            // responsive
            @media #{$md-device, $xxs-device} {
                font-size: 24px;
            }
        }
    }
}
.product-countdown {
    &--sp-product {
        margin-top: 0;
        justify-content: space-between;
        border: 1px solid $bdr-color;
        border-top: 0;
        .single-countdown {
            background-color: transparent;
            border-right: 1px solid $bdr-color;
            border-radius: 0;
            margin-right: 0;
            width: 100%;
            &:last-child {
                border-right: none;
            }
            &__time {
                font-size: 26px;
                font-weight: 600;
                color: $paragraph;
            }
            &__text {
                font-weight: 400;
            }
        }
    }
}
/*----- deals tab area end -----*/

/*------ post category start ------*/
.post-category {
    border: 1px solid $bdr-color;
    padding: 60px 30px;
    // responsive
    @media #{$lg-device, $md-device, $sm-device} {
        padding: 50px 20px;
    }
    @media #{$xs-device} {
        padding: 30px 20px;
        text-align: center;
        margin-bottom: 30px;
    }
}
.post-cat-title {
    font-size: 28px;
    font-weight: 600;
    line-height: 1;
    padding-bottom: 23px;
    // responsive
    @media #{$lg-device} {
        font-size: 25px;
    }
}
.post-cat-list {
    li {
        margin-bottom: 5px;
        &:last-child {
            margin-bottom: 0;
        }
        a {
            color: $black-soft;
            &:hover {
                color: $theme-color;
            }
        }
    }
}
/*------ post category end ------*/

/*------- common style css end -------*/

