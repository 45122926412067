/*-------- category area start --------*/
.category-wrapper {
    margin-top: -100px;
    transform: translateY(100px);
}

.single-category-item {
    position: relative;
    padding: 60px 20px 35px 20px;
    &:before,
    &:after {
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        content: '';
        z-index: -1;
        position: absolute;
        transition: 0.4s;
        background-image: url(../../assets/img/category/cat-bg.jpg);
        background-repeat: no-repeat;
    }
    &:after {
        opacity: 0;
        visibility: hidden;
        transition: 0.4s;
        background-image: url(../../assets/img/category/cat-hover.jpg);
        background-repeat: no-repeat;
    }
    &:hover {
        &:before {
            opacity: 0;
            visibility: hidden;
        }
        &:after {
            opacity: 1;
            visibility: visible;
        }
    }
    .category-thumb {
        margin-bottom: 20px;
    }
}
.category-title {
    line-height: 1;
    text-align: center;
    margin-bottom: 55px;
    a {
        font-size: 16px;
        font-weight: 900;
        color: $white;
        line-height: 1;
    }
}
.shop-collection {
    margin-top: 70px;
}
.shop-btn {
    color: $white;
    font-size: 20px;
    line-height: 1;
    font-weight: 700;
    padding: 15px 25px;
    display: inline-block;
    background-color: #3e3e3e;
    border-radius: 50px;
    &:hover {
        color: $white;
        background-color: $black-soft;
    }
    // responsive
    @media #{$sm-device} {
        font-size: 18px;
        padding: 12px 20px;
    }
}
/*-------- category area end --------*/