/*------ hero slider area css start ------*/
/*-------------------------
02. Slider area
--------------------------*/
.hero-slider-item {
    height: 730px;
    display: flex;
    align-items: center;
    // responsive
    @media #{$md-device} {
        height: 550px;
    }
    @media #{$sm-device} {
        height: 450px;
    }
    @media #{$xs-device} {
        height: 350px;
    }
    &.hero-1 {
        .slide-title {
            font-weight: 300;
            color: $theme-color;
        }
    }
}
.hero-slider {
    &--style-3 {
        .hero-slider-item {
            height: 530px;
            // responsive
            @media #{$sm-device} {
                height: 350px;
            }
        }
        .slide-title {
            font-size: 60px;
            font-weight: 500;
            span {
                display: block;
            }
            // responsive
            @media #{$md-device} {
                font-size: 46px;
            }
            @media #{$sm-device} {
                font-size: 32px;
            }
            @media #{$xs-device} {
                font-size: 26px;
            }
        }
        .slide-subtitle {
            font-size: 20px;
            font-weight: 400;
            padding-top: 30px;
            // responsive
            @media #{$sm-device} {
                padding-top: 20px;
                padding-bottom: 30px;
            }
        }
    }
    &--style-4 {
        margin-top: 30px;
        .hero-slider-item {
            height: 550px;
            // responsive
            @media #{$lg-device} {
                height: 455px;
            }
            @media #{$md-device, $sm-device} {
                height: 348px;
            }
        }
        .slide-title {
            font-size: 46px;
            font-weight: 500;
            span {
                display: block;
            }
            @media #{$md-device, $sm-device} {
                font-size: 32px;
            }
            @media #{$xs-device} {
                font-size: 26px;
            }
        }
    }
}
.hero-slider-content {
    .btn-large {
        margin-top: 40px;
        @media #{$sm-device} {
            margin-top: 20px;
        }
    }
    &.content-top {
        margin-top: 100px;
        @media #{$md-device, $sm-device} {
            margin-top: 50px;
        }
    }
}
.slide {
    &-title{
        font-size: 160px;
        font-weight: 900;
        line-height: 1;
        color: #faec07;
        font-family: $special-font;
        // responsive
        @media #{$md-device} {
            font-size: 100px;
        }
        @media #{$sm-device} {
            font-size: 70px;
        }
        @media #{$xs-device} {
            font-size: 35px;
        }
        @media #{$xxs-device} {
            font-size: 30px;
        }
    }  
    &-subtitle {
        font-size: 46px;
        font-weight: 500;
        line-height: 1.1;
        color: $white;
        font-family: $special-font;
        span {
            display: block;
        }
        // responsive
        @media #{$sm-device} {
            font-size: 32px;
        }
        @media #{$xs-device} {
            font-size: 24px;
            padding-top: 8px;
        }
    }
}

.btn-hero {
    color: $white;
    font-size: 15px;
    line-height: 1;
    padding: 14px 30px;
    display: inline-block;
    border-radius: 50px;
    background-color: $theme-color;
    margin-top: 38px;
    // responsive
    @media #{$xs-device} {
        margin-top: 25px;
    }
    &:hover {
        color: $white;
        background-color: $black-soft;
    }
}

/* .hero-overlay {
    position: relative; 
    &:before {
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        content: '';
        position: absolute;
        background-color: rgba($white, 0.7);
        display: none;
        // responsive
        @media #{$sm-device} {
            display: block;
        }
    }
} */
/*------ hero slider area css end ------*/

// slider text animation
.slick-active {
    .hero-slider-content {
        &.slide-1 {
            h1 {
                animation: fadeInRight 0.5s linear alternate;
            }
            h2 {
                animation: fadeInRight 0.8s linear alternate;
            }
            h3,
            h4 {
                animation: fadeInRight 1s linear alternate;
            }
            a {
                animation: slideInUp 1.2s linear alternate; 
            }
        } 
        &.slide-2 {
            h1 {
                animation: fadeInRight 0.5s linear alternate;
            }
            h2 {
                animation: fadeInRight 0.8s linear alternate;
            }
            h3,
            h4 {
                animation: fadeInRight 1s linear alternate;
            }
            a {
                animation: slideInUp 1.2s linear alternate; 
            }
        }
    }
}


.fixed-banner {
    background-position: top center;
    background-size: auto;
}