.category-dropdown-wrapper {
    height: 100%;
    box-shadow: -1px 0px 3px 0px #cccccc;
    // responsive
    @media #{$sm-device} {
        display: none;
    }
}
.header-box__3 {
    .category-toggle-wrap {
        .category-toggle {
            background-color: $theme-color;
        }
    }
}
.category-toggle-wrap {
    position: relative;
    .category-toggle {
        cursor: pointer;
        color: $white;
        font-size: 17px;
        font-weight: 900;
        line-height: 1;
        padding: 20px 15px;
        background-color: $theme-color-2;
        i {
            font-size: 16px;
            line-height: 1;
            float: right;
        }
        &.mobile-dev {
            padding: 15px;
            margin-top: 15px;
            font-weight: 700;
            background-color: $theme-color;
        }
    }
    nav {
        &.category-menu {
            border-top: none;
            top: 100%;
            left: 0;
            width: 100%;
            position: absolute;
            display: none;
            background-color: $white;
            // responsive
            @media #{$md-device, $sm-device} {
                z-index: 9;
            }
            &-style_2 {
                display: block;
                // responsive
                @media #{$lg-device} {
                    display: none;
                }
            }
            
            & > ul {
                &.categories-list {
                    padding: 17px 20px;
                    border: 1px solid $bdr-color;
                }
                
                // Responsive
                @media #{$md-device, $sm-device}{
                    max-height: 300px;
                    overflow-y: auto;
                }
                
                & > li {
            
                    // Responsive
                    @media #{$md-device, $sm-device}{
                        position: relative;
                    }
                    
                    // Has Dropdown
                    &.menu-item-has-children {
                        position: relative;
                        & > a {
                            &::after {
                                content: "\f105";
                                position: absolute;
                                right: 0;
                                top: 50%;
                                line-height: 1;
                                font-family: $font-font_awesome;
                                transform: translateY(-50%);
                                // Responsive
                                @media #{$md-device, $sm-device}{
                                    display: none;
                                }
                            }
                        }
                    }
                    
                    // Plus Icon For Tablet & Mobile Device
                    & .menu-expand {
                        position: absolute;
                        right: 0;
                        top: 0;
                        cursor: pointer;
                        height: 50px;
                        width: 40px;
                        line-height: 50px;
                        z-index: 9;
                        text-align: center;
                    }
                    
                    & > a {
                        display: block;
                        padding: 9px 0;
                        line-height: 24px;
                        font-size: 14px;
                        color: $paragraph;
                        position: relative;
                        text-transform: capitalize;
                        border-bottom: 1px solid $bdr-color;
                        // Hover
                        &:hover {
                            color: $theme-color;
                        }
                    }
                    
                    &:first-child {
                        a {
                            padding-top: 0;
                            // responsive
                            @media #{$md-device, $sm-device} {
                                padding-top: 10px;
                            }
                        }
                    }
                    &:last-child {
                        a {
                            padding-bottom: 0;
                            border-bottom: none;
                            // responsive
                            @media #{$md-device, $sm-device} {
                                padding-bottom: 10px;
                            }
                        }
                    }
                    &:hover {
                        & .category-mega-menu {
                            top: 0;
                            z-index: 9;
                            opacity: 1;
                            visibility: visible;
                        }
                    }
                    .category-mega-menu {
                        position: absolute;
                        left: calc(100% + 20px);
                        top: 110%;
                        width: 800px;
                        display: flex;
                        flex-wrap: wrap;
                        padding: 30px;
                        opacity: 0;
                        z-index: -9;
                        visibility: hidden;
                        transition: 0.4s;
                        background-color: $white;
                        border: 1px solid $bdr-color;
                            
                        // Responsive
                        @media #{$lg-device} {
                            width: 600px;
                            padding-bottom: 5px;
                        }
                        @media #{$md-device, $sm-device}{
                            position: static;
                            z-index: 9;
                            opacity: 1;
                            visibility: visible;
                            background-color: transparent;
                            padding: 0;
                            width: 100%;
                            transition: none;
                            border: none;
                        }
                        &.two-column {
                            width: 400px;
                            // responsive
                            @media #{$md-device, $sm-device} {
                                width: 100%;
                            }
                            & > li {
                                width: 50%;
                                // responsive
                                @media #{$md-device, $sm-device} {
                                    width: 100%;
                                }
                            }
                        }

                        &.three-column {
                            width: 600px;
                            // responsive
                            @media #{$md-device, $sm-device} {
                                width: 100%;
                            }
                            & > li {
                                width: 33.33%;
                                // responsive
                                @media #{$md-device, $sm-device} {
                                    width: 100%;
                                }
                            }
                        }
                        
                        & > li {
                            float: left;
                            width: 25%;
                            padding: 0 15px;
                            
                            // Responsive
                            @media #{$lg-device} {
                                width: 50%;
                                margin-bottom: 15px;
                            }
                            @media #{$md-device, $sm-device}{
                                flex: 1 0 100%;
                                padding: 0;
                                position: relative;
                            }
                            
                            & > a {
                                display: block;
                                font-size: 15px;
                                font-weight: 500;
                                color: $black-soft;
                                text-transform: uppercase;
                                margin-bottom: 10px;
                                position: relative;
                                &:before,
                                &:after {
                                    left: 0;
                                    bottom: -3px;
                                    content: "";
                                    position: absolute;
                                    height: 2px;
                                    width: 100%;
                                    background-color: $bdr-color;
                                }
                                &:after {
                                    width: 50%;
                                    background-color: $theme-color;
                                }
                            
                                // Responsive
                                @media #{$md-device, $sm-device}{
                                    padding: 5px 20px;
                                    line-height: 30px;
                                    font-size: 14px;
                                    color: #606060;
                                    font-weight: 400;
                                    position: relative;
                                    margin: 0;
                                    text-transform: inherit;
                                    &:before,
                                    &:after {
                                        display: none;
                                    }
                                }
                            }
                            
                            & > ul {
                                & li {
                                    &:last-child {
                                        border: none;
                                    }
                                    
                                    // Child Selector
                                    &:last-child {
                                        margin-bottom: 0;
                                    }
                                    
                                    & a {
                                        display: block;
                                        font-size: 14px;
                                        color: #606060;
                                        line-height: 30px;
                                        padding-bottom: 4px;
                            
                                        // Responsive
                                        @media #{$md-device, $sm-device}{
                                            padding: 5px 20px;
                                            line-height: 30px;
                                            font-size: 14px;
                                            color: #606060;
                                            font-weight: 400;
                                            position: relative;
                                        }
                                        
                                        // Hover
                                        &:hover {
                                            color: $theme-color;
                                        }
                                        
                                    }
                                    // Child Selector
                                    &:last-child {
                                        a {
                                            border-bottom: 0;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

.categories-style-5 {
    .category-toggle {
        background-color: $theme-color;
    }
    nav.category-menu {
        display: block;
    }
}