/*----- testimonial area start -----*/
.testimonial-item {
    max-width: 80%;
    margin: -7px auto -2px;
    // responsive
    @media #{$sm-device} {
        max-width: 100%;
    }
}
.testimonial-desc {
    font-size: 30px;
    font-weight: 900;
    line-height: 1.3;
    color: $white;
    // responsive
    @media #{$sm-device} {
        font-size: 22px;
        font-weight: 700;
    }

}
.testimonial-author {
    width: 80px;
    height: 80px;
    margin: 62px auto 0;
    // responsive
    @media #{$sm-device} {
        margin: 35px auto 0;
    }
}
.author-designation {
    color: $theme-color;
    font-size: 14px;
    font-weight: 900;
    text-transform: uppercase;
    margin-top: 33px;
    // responsive
    @media #{$sm-device} {
        margin-top: 40px;
    }
}
// testimonial style 02
.testimonial-style-02 {
    .testimonial-desc {
        color: $black-soft;
    }
}
/*----- testimonial area end -----*/