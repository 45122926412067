/*------ service policy start ------*/
.service-policy {
    @media #{$sm-device} {
        margin-top: 0;
        padding-top: 53px;
    }
}
.policy{
    &-block {
        padding: 75px 15px;
        background-color: $white;
        border: 1px solid $bdr-color;
        transition: 0.4s;
        // responsive
        @media #{$lg-device, $sm-device} {
            padding: 40px 15px;
        }
        @media #{$md-device, $sm-device} {
            margin-top: 10px;
        }
        &:hover {
            box-shadow: 0 5px 20px 0 rgba(195,195,195,.6);
            .policy-icon {
                &:after {
                    opacity: 1;
                    visibility: visible;
                    animation: spin 10s infinite linear;
                }
            }
        }
    }
    &-icon {
        color: $white;
        font-size: 40px;
        width: 80px;
        height: 80px;
        margin: auto;
        line-height: 84px;
        border-radius: 50%;
        display: block;
        text-align: center;
        background-color: $theme-color;
        position: relative;
        &:after {
            width: calc(100% + 10px);
            height: calc(100% + 10px);
            content: '';
            position: absolute;
            border: 2px dashed $theme-color;
            top: -5px;
            left: -5px;
            border-radius: 50%;
            transition: 0.4s;
            opacity: 0;
            visibility: hidden;
        }
    }
    &-text {
        margin-top: 42px;
        .policy-title {
            font-weight: 400;
            margin-bottom: 20px;
        }
        .policy-desc {
            font-size: 16px;
            line-height: 1.2;
            color: $paragraph-light;
        }
    }
}


// spin keyframe
@keyframes spin {
	0%  {transform: rotate(-360deg);}
	100% {transform: rotate(0deg);}	
}

@keyframes spin {
	0%  {transform: rotate(-360deg);}
	100% {transform: rotate(0deg);}	
}

/* .service-policy {
    .row {
        [class*="col-"] {
            margin-bottom: 30px;
            &:last-child {
                margin-bottom: 0;
            }
        }
    }
} */
/*------ service policy end ------*/