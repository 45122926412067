/*-------- Start My Account Page Wrapper --------*/
.myaccount-tab-menu {
    flex-direction: column;

    a {
        border: 1px solid $bdr-color;
        border-bottom: none;
        color: $black-soft;
        font-weight: 600;
        font-size: 16px;
        display: block;
        padding: 10px 15px;

        &:last-child {
            border-bottom: 1px solid $bdr-color;
        }

        &:hover,
        &.active {
            background-color: $theme-color;
            border-color: $theme-color;
            color: $white;
        }

        i.fa {
            font-size: 14px;
            text-align: center;
            width: 25px;
        }
    }
}

#myaccountContent {
    // Responsive
    @media #{$sm-device} {
        margin-top: 30px;
    }
}

.myaccount-content {
    border: 1px solid #eeeeee;
    padding: 30px;

    @media #{$xs-device} {
        padding: 20px 15px;
    }

    form {
        margin-top: -20px;
    }

    h5 {
        border-bottom: 1px dashed #ccc;
        padding-bottom: 10px;
        margin-bottom: 25px;
    }

    .welcome {
        a {
            color: $black-soft;

            &:hover {
                color: $theme-color;
            }
        }

        strong {
            font-weight: 500;
            color: $theme-color;
        }
    }
    fieldset {
        margin-top: 30px;
        & legend {
            color: $black-soft;
            font-size: 18px;
            line-height: 1.2;
            font-weight: 700;
            margin-top: 20px;
            border-bottom: 1px dashed #ccc;
        }
    }
}

.myaccount-table {
    white-space: nowrap;
    font-size: 14px;

    table,
    .table {
        th {
            color: $black-soft;
            padding: 10px;
            font-weight: 400;
            background-color: #f8f8f8;
            border-color: #ccc;
            border-bottom: 0;
        }

        td {
            padding: 10px;
            vertical-align: middle;
            border-color: #ccc;
        }
    }
}

.saved-message {
    background-color: $light-white;
    border-top: 3px solid $theme-color;
    border-radius: 5px 5px 0 0;
    font-weight: 400;
    font-size: 15px;
    color: $paragraph;
    padding: 20px;
}

/*-------- Start My Account Page Wrapper --------*/